
import { Delete, Edit, View } from "@element-plus/icons";
import { onMounted, defineComponent, inject, ref, toRefs } from "vue";
import { RespBodyList, RespBody } from "@/apis/interface/Resp";
import { ISysRole, SysRoleModel } from "@/apis/interface/ISysRole";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  props: {
    terminal: {
      type: String,
      default: "applet"
    }
  },
  setup(props) {
    const { terminal } = toRefs(props);
    const apiSysRole = inject("apiSysRole") as ISysRole;

    let dataList = ref<SysRoleModel[]>([]);

    let loadData = async () => {
      const resp = await apiSysRole.getList(terminal.value);
      const pager = resp as RespBodyList<SysRoleModel>;
      dataList.value = pager.object;
      // console.log("==>", dataList);
    };

    let formModel = ref<SysRoleModel | null>(new SysRoleModel({ terminal }));

    let editObject = (data: SysRoleModel) => {
      formModel.value = data;
    };

    let newObject = () => {
      formModel.value = new SysRoleModel({ terminal: terminal.value });
    };

    let submit = async () => {
      let model: SysRoleModel | undefined = formModel.value?.getFormModel();
      if (model) {
        let result = (await apiSysRole.add(model)) as RespBody<boolean>;
        formModel.value = new SysRoleModel({ terminal });
        if (result.object) {
          ElMessage({
            message: "保存成功！",
            type: "success"
          });
          return;
        }
      }
      ElMessage({
        message: "操作失败",
        type: "warning"
      });
    };

    let cancle = async () => {
      formModel.value = new SysRoleModel({ terminal });
    };

    onMounted(() => {
      loadData();
    });

    return {
      dataList,
      formModel,
      editObject,
      newObject,
      submit,
      cancle,
      icon: {
        delete: Delete,
        edit: Edit,
        view: View
      }
    };
  }
});
